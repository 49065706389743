// WhatsAppButton.jsx
import React from 'react';
import { FaWhatsapp } from 'react-icons/fa'; // Import WhatsApp icon
import './WhatsAppButton.css'; // CSS file for styling

const WhatsAppButton = () => {
    const openWhatsApp = () => {
        const phoneNumber = '7223859729'; // Replace with your WhatsApp number
        const message = encodeURIComponent("Hello! I need some help.");
        const url = `https://wa.me/${phoneNumber}?text=${message}`;
        window.open(url, '_blank'); // Opens in a new tab
    };

    return (
        <div className="whatsapp-button" onClick={openWhatsApp}>
            <FaWhatsapp size={30} color="white" />
        </div>
    );
};

export default WhatsAppButton;
