import React from 'react';
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import './GauSeva.css';  
import DonationForm from './donationForm';
import krishna from './assets/krishna.png';
import gau from './assets/gau.png';
import donat from './assets/donat.png';
import gaushala from './assets/gaushala.png';
import gauMobile from './assets/gauMobile.png';

const GauSeva = () => {
  return (
    <>
      <Header/>

      {/* Desktop View */}
        <div className='desktop'>
          {/* Gau Section */}
          <div className="gau-section">
            <img src={gau} alt="Gau" className="gau" />
          </div>

          {/* Donation Form */}
          <DonationForm/>

          {/* Krishna Section */}
          <div className="krishna-section">
            <img src={krishna} alt="Krishna" className="krishna" />
          </div>

          {/* Gaushala Section */}
          <div className="gaushala-section">
            <img src={gaushala} alt="Gaushala" className="gaushala" />
          </div>

          {/* Donation Section */}
          <div className="donat-section">
            <img src={donat} alt="Donat" className="donat" />
          </div>
        </div>

        {/* Mobile View */}
        <div className='mobile'> 
          <div>
            <img src={gauMobile} alt="Gau Maa ta" />
          </div>
        </div>
      <Footer/>
    </>
  );
};

export default GauSeva;
